import React, { useRef } from 'react';
import Book_card from './bookcard.css';
import Star from './star.svg';
import NoCover from "./no-cover";
import { extractAuthorNames } from '../helpers/utils';
const BookCard = (bookData) => {
  const book = bookData.node;
  const bookdetailsBtn = useRef();
  return (
    <Book_card>
      <div className="content-wrapper">
      <div>
        <a href={book.content.childMarkdownRemark.fields.slug} style={{display: "inline-block"}} aria-label="Book Cover">
        {
          book.image_url.indexOf("nophoto")>0 ? <NoCover {...book}/> :<img
          src={book.image_url}
          alt={book.title}
          className="book-card-img"
          width="196"
          height="301"
        />
        }
        </a>
        </div>
        <div className="card-content">
          <a href={book.content.childMarkdownRemark.fields.slug}><div className="book-name">{book.title}</div></a>
          <div className="book-by">by {extractAuthorNames(book.authors)}</div>
          <div className="rate">
            {
            Array.from({length: Math.min(Math.round(book.average_rating), 5)}).map((i, k)=> <img src={Star} alt="star" width="15px" height="15px" key={k}/>)
            }
            <span className="book-voters card-vote">{book.ratings_count==undefined || book.ratings_count==0?19:book.ratings_count} voters</span>
          </div>
          <div className="book-sum card-sum">
            { book.original_title ? <h3>{book.original_title}</h3> : '' }
            { book.title_without_series ? <h3>{book.title_without_series}</h3> : '' }
            <div
              dangerouslySetInnerHTML={{
                __html: book.description,
              }}
            />
          </div>
          <a href={book.content.childMarkdownRemark.fields.slug} className="book-see">See The Book</a>
        </div>
      </div>
      <div className="likes">
        <div className="like-profile">
          <img
            src="https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=htmlFormat&fit=crop&w=28&q=100"
            alt=""
            className="like-img"
            width="28"
            height="28"
            loading="lazy"
          />
        </div>

        <div className="like-profile">
          <img
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=htmlFormat&fit=crop&w=28&q=100"
            alt=""
            className="like-img"
            width="28"
            height="28"
            loading="lazy"
          />
        </div>

        <div className="like-profile">
          <img
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=htmlFormat&fit=crop&w=28&q=100"
            alt=""
            className="like-img"
            width="28"
            height="28"
            loading="lazy"
          />
        </div>
        <div className="like-name">
          <span>Samantha William</span> and<span> 2 others</span> like
          this
        </div>
      </div>
    </Book_card>
  );
};

export default BookCard;