import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { extractAuthorNames } from '../helpers/utils';

const WrapperElement = styled.div`
 background-color: rgb(68, 222, 180);
 font-size: 3.4rem;
 position: relative;
 display: block;
 width: 200px;
 height: 310px;
 overflow: hidden;
p,
h2 {
 color: rgb(37, 43, 86);
}

.letter-one {
 color: rgba(255, 255, 255, 1);
}

.letter-two {
 color: rgba(72, 236, 72, .6);
}

.letter-three {
 color: rgba(255, 118, 118, .8);
}

.letter-four {
 color: rgba(255, 207, 78, .75);
}

.letter-five {
 color: rgba(36, 41, 91, .7);
}


/* -------- LAYOUT  -------- */

div {
 display: block;
 width: 100%;
 height: 100%;
 margin: 1.5vw auto;
}

p {
 font-variant: small-caps;
 font-size: 0.8rem;
 letter-spacing: .14vw;
 position: absolute;
 top: 0;
 right: 0;
 padding-left: 9%;
 margin-top: 9.5%;
 z-index: 10;
 text-align: left;
 width: 91%;
 text-shadow: 0 0 1px #fff;
}

p>span {
 font-size: 1.2rem;
 vertical-align: sub;
 padding-left: 3px;
}

p>span:after {
 content: " ";
 position: absolute;
 display: inline-block;
 height: 70%;
 margin-top: 0;
 margin-left: -.1em;
 border-left: 1px solid rgb(255, 207, 78);
}

div[class^="letter-"] {
 position: absolute;
 font-size: 12em;
 margin-left: 0;
 margin-top: -1.6rem;
 text-align: center;
 font-family: serif;
 display: flex;
 justify-content: center;
 flex-direction: column;
 top: -13%;
}

.letter-one {
 font-size: 4.7em;
 margin-left: 0.9rem;
 margin-top: -0.4rem;
}

.letter-two {
 transform: scale(0.87);
}

.letter-three {
 transform: scale(0.68);
}

.letter-four {
 transform: scale(0.5);
}

.letter-five {
 transform: scale(0.36);
}

h2 {
 font-size: .2em;
 position: absolute;
 bottom: 10px;
 margin-left: 12%;
 text-shadow: 0 0 1px #fff;
}

.row-two {
 font-size: 1.6em;
 line-height: 1.4em;
 letter-spacing: -0.11em;
}

.row-three {
 font-size: 1.6em;
 line-height: 0;
 letter-spacing: -0.11em;
}
`;
const NoCover = (book)=>{
   const {title="Ciencias Sociales 7 - 3b: Ciclo Egb / Recorridos", authors, publisher, num_editions} = book;
 return (
  <WrapperElement className="book-card-img">
    <div className="book">
      <p>
       <span>{num_editions || 1}</span>
       {publisher || "Publisher Pub."} 
       <br/>
       by: {extractAuthorNames(authors) || "Jackson Doe"}
      </p>
      <div className="letter-one">{title[0]}</div>
      <div className="letter-two">{title[0]}</div>
      <div className="letter-three">{title[0]}</div>
      <div className="letter-four">{title[0]}</div>
      <div className="letter-five">{title[0]}</div>
      <h2>
         <span className="row-three">{title.split(' ')[0]} {title.split(' ')[1]}</span>
         <br/>
         <span className="row-two">{title.split(' ')[2]} {title.split(' ')[3]}</span>
         <br/> {title.split(' ').splice(4).join(' ')}
      </h2>
   </div>
  </WrapperElement>
 );
};

export default NoCover;