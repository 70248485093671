import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Book_card = styled.div `
  background-color: #fff;
  height: 270px;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: var(--body-color-light);
  width: 49%;
  margin-left: .5%;
  margin-top: 60px;
  .content-wrapper {
    display: flex;
    border-bottom: 1px solid #ebedef;
    position: relative;
  }
  .book-card-img {
    margin-top: -35px;
    border-radius: 2px;
    box-shadow: 0px 1px 7px 2px #c7c9d3;
    border-bottom: 1px solid #dcddde;
    object-fit: cover;
    margin-bottom: 20px;
    transition: 0.3s ease;
    ${MEDIA.TABLET`
      height: 300px;
      max-width: 200px;
    `}
  }
  .card-content {
    color: var(--body-color);
    padding: 30px;
    overflow: hidden;
    position: relative;
    ${MEDIA.TABLET`
      padding-top: 0;
      padding-left: 15px;
      margin-top: -5px;
    `}
  }
  .book-name {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .book-by {
    font-size: 13px;
    color: var(--body-color-light);
    margin-top: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .card-vote {
    color: var(--body-color-light);
  }

  .card-sum {
    color: var(--body-color-light);
    font-size: 13px;
    line-height: 1.6em;
    -webkit-line-clamp: 4;
    height: 60px;
    overflow: hidden;
    margin-top: 15px;
  }

  .content-wrapper {
    display: flex;
    border-bottom: 1px solid #ebedef;
    position: relative;
  }

  .like-profile {
    margin-top: 8px;
    & + & {
      margin-left: -5px;
    }
  }

  .like-img {
    border-radius: 50%;
    width: 28px;
    object-fit: cover;
    border: 2px solid #fff;
  }

  .like-name {
    font-size: 13px;
    margin-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    span {
      font-weight: 600;
    }
  }
  .likes {
    display: flex;
    align-items: center;
  }
  .book-see {
    display: block;
    margin-top: 5px;
    text-align: center;
    background-color: #fff;
    color: #d2363a;
    border: 1px solid;
    font-weight: 600;
    padding: 8px;
    font-size: 14px;
    width: 160px;
    border: 1px solid;
    border-radius: 20px;
    text-decoration: none;
    &:hover {
      color: #ff6e72;
    }
    &.book-blue {
      color: #a4e0eb;
      &:hover {
        color: #22cdec;
      }
    }
    &.book-pink {
      color: #edb9d6;
      &:hover {
        color: #ff6dbe;
      }
    }
    &.book-yellow {
      color: #fdca95;
      &:hover {
        color: #fb9124;
      }
    }
    &.book-purple {
      color: #cbb5e2;
      &:hover {
        color: #a764ec;
      }
    }
  }
  ${MEDIA.MIN_TABLET`
  .book-card-img {
    height: 230px;
    max-width: 155px;
    flex: 0 0 50%;
  }
`}
  ${MEDIA.TABLET`
    width: 100%;
    height: auto;
    .content-wrapper {
        flex-direction: column;
    }
`}

.book-rate > label {
    color: #cccccc;
  }
  .rate {
    display: inline-block;
    white-space: nowrap;
  }

  .book-rate {
    > input:checked ~ label {
      color: #ff9700;
    }

    &:not(:checked) > label:hover {
      color: #ff9700;

      ~ label {
        color: #ff9700;
      }
    }

    > {
      input:checked {
        + label:hover,
        ~ label:hover {
          color: #ff9700;
        }
      }

      label:hover ~ input:checked ~ label,
      input:checked ~ label:hover ~ label {
        color: #ff9700;
      }
    }
  }
`;
export default Book_card;